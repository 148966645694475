import * as React from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import loadable from '@loadable/component'
import joinClasses from '/src/functions/joinClasses'
import './styles.scss'

const ButtonLink = loadable(() =>
    import('/src/components/Basic/Buttons/ButtonLink')
)
const Section = loadable(() =>
    import('/src/components/Structure/Section/Section')
)

const StandaloneButton = ({ blockSettings, callToAction, utm, className }) => {
    const paddingSize = blockSettings?.paddingSize
    const blockClass = blockSettings?.blockClass

    const classes = ['c-standalone-button', blockClass, ...(className ? [className] : [])]

    const concatenatedClasses = joinClasses(classes)

    return (
        <Section
            variant={'light'}
            size={paddingSize}
            className={concatenatedClasses}
        >
            {callToAction && (
                <ButtonLink
                    data={callToAction}
                    size={'lg'}
                    icon={'arrow'}
                    utm={utm}
                />
            )}
        </Section>
    )
}

StandaloneButton.propTypes = {
    callToAction: PropTypes.shape({
        url: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        target: PropTypes.string
    }).isRequired,
    /**
     * Optional page UTMs - e.g. `?utm_campaign=campaign_name&utm_source=source_name`
     */
    utm: PropTypes.string,
    /**
     * Optional CSS classes
     */
    className: PropTypes.string
}

StandaloneButton.defaultProps = {
    callToAction: {
        url: '#',
        title: 'Button Title'
    }
}

export default StandaloneButton

export const query = graphql`
    fragment StandaloneButtonForPageBuilder on WpPage_Acfpagebuilder_Layouts_StandaloneButton {
        fieldGroupName
        blockSettings {
            paddingSize
            blockClass
        }
        callToAction {
            target
            title
            url
        }
    }
`
